import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		// ...
		proClassName: '',
		technology: '',
		caseClassName: '',
		newsClassName: ''
	},
	mutations: {
		// ...
	},
	actions: {
		// ...
	},
	 plugins: [createPersistedState({
	    storage: window.sessionStorage, // 或者localStorage
	  })]
});

