export default [
  {
  path: '/home/index',
  name:'home',
  component: () => import('@/views/h5/home/index')
},
{
  path: '/prod/index',
  name:'prod',
  component: () => import('@/views/h5/prod/index')
},
{
  path: '/news/index',
  name:'news',
  component: () => import('@/views/h5/news/index')
},
//根据新闻的id跳转到相应页面
{
  path: '/newsdetail/:id',//动态路由参数：id
  name:'newsdetail',
  component: () => import('@/views/h5/components/newsdetail')
},

{
  path: '/connect/index',
  name:'connect',
  component: () => import('@/views/h5/connect/index')
},
{
  path: '/intro/index',
  name:'intro',
  component: () => import('@/views/h5/intro/index')
},
{
  path: '/culture/index',
  name:'culture',
  component: () => import('@/views/h5/culture/index')
},
{
  path: '/honer/index',
  name:'honer',
  component: () => import('@/views/h5/honer/index')
},
{
  path: '/flow/index',
  name:'flow',
  component: () => import('@/views/h5/flow/index')
},
//根据点击不同分类 跳转到该分类下的产品列表
{
  path: '/prodlist/:id',//动态路由参数：id
  name:'prodlist',
  component: () => import('@/views/h5/components/prodlist'),
  beforeRouteUpdate(to, from, next) {
    // 判断参数是否发生变化，例如判断 id 参数
    if (to.params.id !== from.params.id) {
      // 参数发生变化，执行你的逻辑，比如重新加载数据
      // 你可以调用 API 或者触发 Vuex action 来重新获取数据
      window.location.reload()
    }
    
    // 继续导航
    next()
  }
},

{
  path: '/proddetail/:id',//动态路由参数：id
  name:'proddetail',
  component: () => import('@/views/h5/components/proddetail')
},

{
  path: '/case/index',
  name:'case',
  component: () => import('@/views/h5/case/index')
},
{
  path: '/Casedetail/:id',//动态路由参数：id
  name:'Casedetail',
  component: () => import('@/views/h5/components/Casedetail')
},
{
  path: '/solution/index',
  name:'solution',
  component: () => import('@/views/h5/solution/index')
},
{
  path: '/solutiondetail/:id',//动态路由参数：id
  name:'solutiondetail',
  component: () => import('@/views/h5/components/solutiondetail')
},
{
  path: '/leave/index',
  name:'leave',
  component: () => import('@/views/h5/leave/index')
},

]