<template>
	<div id="app">
		<!-- 通过 <router-view> 组件，路由会根据当前的路由路径渲染对应的页面组件。 -->
		<router-view />
	</div>
</template>
<script>
	import {
		login
	} from '@/api/login/login.js'
	export default {
		data() {
			return {}
		},
		//App.vue
		 mounted() {
			
		},
		async created() {
			// await login({
			// 	phone: '13312345678',
			// 	password: '123456',
			// }).then((res) => {
			// 	sessionStorage.setItem('token', res.data.token)
			// 	sessionStorage.setItem('userID', res.data.id)
			// })
			// this.loginInit()
		},
		methods: {
			loginInit() {

			},
			//App.vue
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)
				return flag
			},
		},
	}
</script>
<style>
	@import './assets/css/common.css';
	@import './assets/css/font.css';

	#app {
		font-family: Helvetica, Arial, 'PingFang SC', 'PingFang SC', 'Microsoft YaHei',
			'WenQuanYi Micro Hei', 'tohoma,sans-serif';
		color: #333;
		margin: 0;
		padding: 0;
		/* 导航栏固定 */
		/* padding-top: 120px; */
	}

	.Vhtml img {
		width: 350px;
		height: 236px;
	}

	.Vwsp {
		white-space: pre-wrap;
	}

	.hand {
		cursor: pointer;
	}

	.fakeItem {
		width: 373px;
		height: 0;
		border: 2px solid green;
		box-sizing: border-box;
		opacity: 0;
		/* visibility: hidden; */
	}

	::v-deep .ql-align-center {
		display: flex;
		justify-content: center;
	}
</style>