import Vue from 'vue'
import VueRouter from 'vue-router'
import h5router from './h5/index.js'
import home from './home/index.js'
Vue.use(VueRouter)
const routes = [{
		path: '/',
		name: 'index',
		component: () => import('@/views/official/index')
	},
	...home,
	...h5router
]

const router = new VueRouter({
	routes,
	mode: 'history',
	// base: process.env.production,
	// 根据开发环境，设置拼接路由
	  // base:  process.env.NODE_ENV === "production" ? "/company/" : "/company/",
})

export default router