export default [{
		path: '/News',
		component: () => import('@/views/official/News/index')
	}, {
		path: '/News/detail',
		name:'Newsdetail',
		component: () => import('@/views/official/News/detail')
	}, {
		path: '/Application',//应用
		component: () => import('@/views/official/Application/index')
	}, {
		path: '/Solution/detail',
		component: () => import('@/views/official/Solution/detail')
	}, {
		path: '/Contact',
		component: () => import('@/views/official/Contact/index')
	}, {
		path: '/Case',//案例
		component: () => import('@/views/official/Case/index')
	}, {
		path: '/Customer/detail',
		component: () => import('@/views/official/Customer/detail')
	}, {
		path: '/About',
		component: () => import('@/views/official/About/index')
	}, {
		path: '/Products',//产品展示
		component: () => import('@/views/official/Products/index')
	},
	{
		path: '/AboutUs',//走进凯元
		component: () => import('@/views/official/AboutUs/index')
	},
	{
		path: '/Talent',//人才中心
		component: () => import('@/views/official/Talent/index')
	}
]